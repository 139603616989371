import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MeetingTileComponent } from './meetings/meeting-tile.component';
import { MeetingDetailComponent } from './meetings/meeting-detail.component';
import { RaceCardComponent } from './racecard/race-card.component';
import { HorseStatsForRaceComponent } from './horse/horse-stats.component';
import { FastResultsComponent } from './results/fast-results.component';
import { ResultsComponent } from './results/results.component';
import { CourseStatsComponent } from './course/course-stats.component';

const childRoutes: Routes = [
    {
        path: '', redirectTo: 'meetings', pathMatch: 'full'
    },
    {
        path: 'meetings', component: MeetingTileComponent
    },
    {
        path: 'fast-results', component: FastResultsComponent
    },
    {
        path: 'results', component: ResultsComponent
    },
    {
        path: 'results/:id', component: FastResultsComponent
    },
    {
        path: 'meeting/:id', component: MeetingDetailComponent
    },
    {
        path: 'horsestatsforrace/:id', component: HorseStatsForRaceComponent
    },
    {
        path: 'coursestats/:id', component: CourseStatsComponent
    }
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(childRoutes);
