import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../shared/constants';
/*
 * Converts a silk to a full silk url
*/
@Pipe({name: 'toFullSilkUrl'})
export class SilkPipe implements PipeTransform {
  constructor(private constants: Constants) {}

  transform(value: string): string {
    return this.constants.getSilksBaseUrl() + value;
  }
}
