import { Component, OnInit } from '@angular/core';
import { MeetingService } from '../services/meeting-services';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../shared/constants';
import { DateToUtcPipe } from '../pipes/datetoutc.pipe';
import { ICourseStatistic, IMeeting, IRace, ICourseWeather } from 'app/shared/models/data.models';
import { MeetingHelper } from '../shared/helpers/meeting';


@Component({
    moduleId: module.id,
    templateUrl: 'course-stats.component.html',
    providers: [MeetingService, DatePipe],
})

export class CourseStatsComponent implements OnInit {

    meetingId: number;

    meetings: IMeeting[];
    meeting: IMeeting;
    races: IRace[];
    trainerStats: ICourseStatistic[];
    jockeyStats: ICourseStatistic[];
    hotTrainerStats: ICourseStatistic[];
    hotJockeyStats: ICourseStatistic[];
    courseWeather: ICourseWeather;

    constructor(private meetingService: MeetingService, private route: ActivatedRoute, private router: Router, private datePipe: DatePipe,
        private utcPipe: DateToUtcPipe, private constants: Constants) {

        this.meetingId = route.snapshot.params['id'];
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.meetingService.getRacesByMeeting(this.meetingId)
            .subscribe(races => this.races = races, null, () => this.transformRacesToMeeting());

        this.meetingService.getWeatherByMeetingId(this.meetingId)
            .subscribe(courseWeather => this.courseWeather = courseWeather);

        this.meetingService.getCourseTrainerStatsForMeeting(this.meetingId)
            .subscribe(stats => this.trainerStats = stats);

        this.meetingService.getCourseJockeyStatsForMeeting(this.meetingId)
            .subscribe(stats => this.jockeyStats = stats);

        this.meetingService.getCourseHotTrainerStatsForMeeting(this.meetingId)
            .subscribe(stats => this.hotTrainerStats = stats);

        this.meetingService.getCourseHotJockeyStatsForMeeting(this.meetingId)
            .subscribe(stats => this.hotJockeyStats = stats);
    }

    transformRacesToMeeting() {

        // Get all the meetings
        this.meetings = MeetingHelper.TransformRacesToMeetings(this.races);

        // Just get the first meeting as there should only be a singular meeting
        this.meeting = this.meetings[0];

    }
}
