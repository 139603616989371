﻿import { Injectable, Inject } from '@angular/core';
import { Http, Response, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable, interval, timer } from 'rxjs';
import { map, filter, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Constants } from '../shared/constants';
import { IRace, ICourseWeather, IFormHistory, IRunHistory, IHorseSummaryStats, ICourseStatistic, IRaceHorseLiveShow } from '../shared/models/data.models';
import { IRaceHorseStat } from '../shared/models/horse-stats.model';
import { ITopRated } from '../shared/models/top-rated.model';
import { IHorseRating } from '../shared/models/horse-ratings.model';

@Injectable()
export class MeetingService {

    constructor(private http: Http, private constants: Constants) {
    }

    /*Races Methods*/
    getRaces(): Observable<IRace[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/todaysraces')
            .pipe(
                map((res: Response) => <IRace[]>res.json())
            );
    }

    getRacesByMeeting(id: number): Observable<IRace[]> {
        return this.http.get(this.constants.getEqApiUrl()  + 'races/fetchracesbymeetingid/' + id)
            .pipe(
                map((res: Response) => <IRace[]>res.json())
            );
    }

    getWeatherByMeetingId(id: number): Observable<ICourseWeather> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/fetchweatherformeeting/' + id)
            .pipe(
                map((res: Response) => <ICourseWeather>res.json())
            );
    }
    getNextRaceDate(): Observable<IRace> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/fetchnextracedate')
            .pipe(
                map((res: Response) => <IRace>res.json())
            );
    }
    /*Horse Methods*/
    getHorseForm(id: number): Observable<IFormHistory[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'horse/formhistory/' + id)
            .pipe(
                map((res: Response) => <IFormHistory[]>res.json())
            );
    }

    getRunHistory(id: number): Observable<IRunHistory[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'horse/runspastyear/' + id)
            .pipe(
                map((res: Response) => <IRunHistory[]>res.json())
            );
    }

    getSummaryStatsByMeetingId(id: number): Observable<IHorseSummaryStats[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'horse/horsesummarystats/' + id)
            .pipe(
                map((res: Response) => <IHorseSummaryStats[]>res.json())
            );
    }

    getResults(resultsDay: string): Observable<IRace[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/results/?resultsDay=' + resultsDay)
            .pipe(
                map((res: Response) => <IRace[]>res.json())
            );
    }

    getFastResults(resultsDay: Date): Observable<IRace[]> {
        return timer(0, 15000)
            .pipe(
                switchMap(
                    () => this.http.get(this.constants.getEqApiUrl() + 'races/fastresults/?resultsDay=' + resultsDay)
                        .pipe(
                            map((res: Response) => <IRace[]>res.json())
                        )
                )
            );
    }

    getRaceHorseStatsByRaceId(id: number): Observable<IRaceHorseStat[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/racehorsestats/' + id + '?Period=All')
            .pipe(
                map((res: Response) => <IRaceHorseStat[]>res.json())
            );
    }

    getHorseRatingsByRaceId(id: number): Observable<IHorseRating[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/horseratingsbyraceid/' + id + '/1')
            .pipe(
                map((res: Response) => <IHorseRating[]>res.json())
            );
    }

    getRaceTopRated(id: number): Observable<ITopRated[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/toprated/' + id + '/3')
        .pipe(
            map((res: Response) => <ITopRated[]>res.json())
        );
    }

    getCourseJockeyStatsForMeeting(id: number): Observable<ICourseStatistic[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'course/jockeystatsformeeting/' + id)
        .pipe(
            map((res: Response) => <ICourseStatistic[]>res.json())
        );
    }

    getCourseTrainerStatsForMeeting(id: number): Observable<ICourseStatistic[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'course/trainerstatsformeeting/' + id)
        .pipe(
            map((res: Response) => <ICourseStatistic[]>res.json())
        );
    }

    getCourseHotTrainerStatsForMeeting(id: number): Observable<ICourseStatistic[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'course/hottrainerstatsformeeting/' + id)
        .pipe(
            map((res: Response) => <ICourseStatistic[]>res.json())
        );
    }

    getCourseHotJockeyStatsForMeeting(id: number): Observable<ICourseStatistic[]> {
        return this.http.get(this.constants.getEqApiUrl() + 'course/hotjockeystatsformeeting/' + id)
        .pipe(
            map((res: Response) => <ICourseStatistic[]>res.json())
        );
    }

    getLiveShows(id: number): Observable<IRaceHorseLiveShow[]> {
        return timer(0, 15000)
            .pipe(
                switchMap(
                    () => this.http.get(this.constants.getEqApiUrl() + 'races/liveshows/' + id)
                        .pipe(
                            map((res: Response) => <IRaceHorseLiveShow[]>res.json())
                        )
                )
            );
    }

    getRaceStatus(id: number): Observable<string> {
        return this.http.get(this.constants.getEqApiUrl() + 'races/racestatusbyraceid/' + id)
        .pipe(
            map((res: Response) => <string>res.json())
        );
    }

    /*private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }*/

}
