﻿import { OnInit, Component, NgZone } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

import { DatePipe } from '@angular/common';
import { FlickityComponent, FlickityItem } from '../shared/flickity.component';

import { IMeeting, IRace, IRunHistory, IFormHistory, IHorse, IRaceHorse } from '../shared/models/data.models';
import { MeetingHelper } from '../shared/helpers/meeting';
import { MeetingService } from '../services/meeting-services';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadCrumbModel } from '../breadcrumb/breadcrumb.model';

@Component({
    moduleId: module.id,
    templateUrl: 'horse-stats.component.html',
    providers: [MeetingService, DatePipe],
    animations: [
        trigger('focusPanel', [
            state('inactive', style({
                display: 'none'
            })),
            state('active', style({
                display: 'block',
                height: '50%'
            })),
            transition('inactive => active', animate('150ms ease-in')),
            transition('active => inactive', animate('100ms ease-out'))
        ]),

    ]
})
export class HorseStatsForRaceComponent implements OnInit {

    /*Horse variables*/
    horseId: number;
    runHistory: IRunHistory[];
    formHistory: IFormHistory[];
    currentHorse: IHorse;
    currentRaceHorse: IRaceHorse;

    filteredRunHistory: IRunHistory[];
    filterOptions: any = {};

    pinSticker: string;

    /*Meeting and Race variables*/
    meetingId: number;
    meetings: IMeeting[];
    meeting: IMeeting;
    currentRace: IRace;
    races: IRace[];
    raceId: number;

    constructor(private meetingService: MeetingService, private route: ActivatedRoute,
        private zone: NgZone, private datePipe: DatePipe) {
        this.horseId = route.snapshot.params['id'];
        this.route.queryParams.pipe(map(param => param['meetingId'] || 0)).subscribe((meetingId) => this.meetingId = meetingId);
        this.route.queryParams.pipe(map(param => param['raceId'] || 0)).subscribe((raceId) => this.raceId = raceId);
    }

    ngOnInit() {
        // Get the meetings and race objects
        this.meetingService.getRacesByMeeting(this.meetingId)
            .subscribe(races => this.races = races, null, () => this.transformRacesToMeeting());

        this.getHistory();
    }
    getHistory() {
        // Populate the raceform and run history
        this.meetingService.getRunHistory(this.horseId)
            .subscribe(runHistory => {
                this.runHistory = runHistory;
                this.filteredRunHistory = Object.assign([], this.runHistory);
            }, null);

        this.meetingService.getHorseForm(this.horseId)
            .subscribe(formHistory => {
                this.formHistory = formHistory;
            }, null);
    }
    transformRacesToMeeting() {

        // Get all the meetings
        this.meetings = MeetingHelper.TransformRacesToMeetings(this.races);

        // Just get the first meeting as there should only be a singular meeting
        this.meeting = this.meetings[0];

        this.setRace(this.raceId);

        this.route.queryParams.pipe(map(param => param['raceId'] || 0)).subscribe((raceId) => this.setRace(raceId));
    }
    getBreadCrumbModel(): BreadCrumbModel[] {
        const model: BreadCrumbModel[] = [];
        model.push({
            name: 'Race Card',
            url: '/meetings'
        });
        model.push({
            name: this.getRaceInfoText(),
            url: '/meeting/' + this.meetingId,
            queryParams: { raceId: this.raceId }
        });
        model.push({
            name: this.currentHorse.Name,
            url: null
        });
        return model;
    }
    getRaceInfoText(): string {
        const dateText: string = this.datePipe.transform(this.currentRace.RaceDate, 'HH:mm');
        return this.currentRace.Meeting.Course.Name.concat(', ').concat(dateText);
    }
    setRace(raceId: number) {
        console.log(raceId);
        if (raceId === 0) {
            return;
        }

        this.currentRace = this.meeting.Races.filter(filter => filter.Id == raceId)[0];

        // Sort the horses
        this.currentRace.Horses = this.currentRace.Horses.sort((a, b) => a.ClothNo * 10 > b.ClothNo * 10 ? 1 : -1);

        console.log(this.currentRace.Horses);

        // Set the current horse
        this.currentRaceHorse = this.currentRace.Horses.filter(filter => filter.HorseId == this.horseId)[0];
        this.currentHorse = this.currentRaceHorse.Horse;

        // Set the pin sticker
        if (this.currentRaceHorse.PinStickers && this.currentRaceHorse.PinStickers.length > 0) {
            const pinStickerLong = this.currentRaceHorse.PinStickers.find(i => i.PinStickerType.Name === 'Long');

            this.pinSticker = pinStickerLong ? pinStickerLong.PinSticker : null;
        }

    }
    filterHistory() {
        const typePredicated = {
            'All': (h: IRunHistory) => true,
            'Flat': (h: IRunHistory) => (h.RaceType || '').toLowerCase().indexOf('flat') > -1,
            'Jump': (h: IRunHistory) => (h.RaceType || '').toLowerCase().indexOf('jump') > -1,
            shouldIgnore(type: string): boolean {
                if (!type) { return true; }
                if (!this.hasOwnProperty(type)) { return true; }
                return false;
            }
        }
        const resultPredicates = {
            'All': (h: IRunHistory) => true,
            'Won': (h: IRunHistory) => h.FinishPosition === 1,
            'Placed"': (h: IRunHistory) => h.HasPlaced,
            shouldIgnore(result: string): boolean {
                if (!result) { return true; }
                if (!this.hasOwnProperty(result)) { return true; }
                return false;
            }
        }
        
        this.filteredRunHistory = this.runHistory.filter(h => typePredicated.shouldIgnore(this.filterOptions.type) ? h : typePredicated[this.filterOptions.type](h))
                                                 .filter(h => resultPredicates.shouldIgnore(this.filterOptions.result) ? h : resultPredicates[this.filterOptions.result](h))
                                                 .filter(h => !(<any>h).hasOwnProperty(this.filterOptions.targetProp) ? h : h[this.filterOptions.targetProp]);
    }

    changeSelection(index) {
        this.zone.run(() => {
            this.currentHorse = this.currentRace.Horses[index].Horse;
            this.horseId = this.currentHorse.Id;
            this.getHistory();
        });
    }
}