import { Component, OnInit, Input } from '@angular/core';
import { BreadCrumbModel } from './breadcrumb.model';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input()
  public model: BreadCrumbModel[]
  constructor() { }

  ngOnInit() {
  }

}
