import { Pipe, PipeTransform } from '@angular/core';

import { IRaceHorseQuickStatType } from '../shared/models/data.models';

@Pipe({name: 'historyShortName'})
export class HistoryShortNamePipe implements PipeTransform {

  transform(value: IRaceHorseQuickStatType): string {
    switch (value.Name) {
      case 'BeatenFavourite':
          return 'bf';
      case 'Distance':
          return 'd';
      case 'Course':
          return 'c';
      case 'CourseDistance':
          return 'cd';
    }
  }
}
