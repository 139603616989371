﻿﻿import { OnInit, Component, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateToUtcPipe } from '../pipes/datetoutc.pipe';
import { HistoryShortNamePipe } from '../pipes/history-shortname.pipe';
import { IMeeting, IRace, ICourseWeather, IRaceHorse, IHorseSummaryStats, ICasualty, IHeadToHead, IRaceHorseLiveShow } from '../shared/models/data.models';
import { MeetingHelper } from '../shared/helpers/meeting';
import { MeetingService } from '../services/meeting-services';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BreadCrumbModel } from '../breadcrumb/breadcrumb.model';
import { Constants } from '../shared/constants';
import { trigger, state, style } from '@angular/animations';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    templateUrl: 'meeting-detail.component.html',
    providers: [MeetingService, DatePipe, HistoryShortNamePipe],
    animations: [
        trigger('showFilters', [
            state('inactive',
                style({
                    display: 'none'
                })
            ),
            state('active',
                style({
                    display: 'block',
                })
            )
        ])
    ]
})
export class MeetingDetailComponent {

    meetingId: number;
    meetings: IMeeting[];
    raceMeeting: IMeeting[];
    meeting: IMeeting;
    currentRace: IRace;
    races: IRace[];
    meetingRaces: IRace[];
    raceId: number;
    courseWeather: ICourseWeather;
    horseCache: IRaceHorse[];
    summaryStats: IHorseSummaryStats[];
    nonFilteredRunners: IRaceHorse[];
    runners: IRaceHorse[];
    nonRunners: IRaceHorse[];
    headToHeads: IHeadToHead[] = [];
    liveShows: IRaceHorseLiveShow[];
    raceStatus: string;
    racePreview: string;

    liveShowSubscription: Subscription;

    /*Filters*/
    filtersActive = 'inactive';

    activeFilters: string[] = [];

    constructor(private meetingService: MeetingService, private route: ActivatedRoute,
                private zone: NgZone, private router: Router, private datePipe: DatePipe,
                private utcPipe: DateToUtcPipe, private constants: Constants) {

            route.params.subscribe(vals => this.initMeeting(vals));
    }


    initMeeting(vals) {

        this.meetingId = vals.id;

        this.getRacesByMeeting();

        this.meetingService.getRaces()
            .subscribe(races => this.meetingRaces = races, null, () => this.transformRacesToMeetings());

        this.getMeetingWeather();

        this.getHorseSummaryStats();
    }

    transformRacesToMeetings() {
        this.meetings = MeetingHelper.TransformRacesToMeetings(this.meetingRaces);

        this.meetings = this.meetings.sort((a, b) => a.Course.Name.toLocaleLowerCase() > b.Course.Name.toLocaleLowerCase() ? 1 : -1);
    }

    transformRacesToMeeting() {

        // Get all the meetings
        this.raceMeeting = MeetingHelper.TransformRacesToMeetings(this.races);

        // Just get the first meeting as there should only be a singular meeting
        this.meeting = this.raceMeeting[0];

        this.raceId = this.meeting.Races[0].Id;

        this.setRace(this.raceId);

        this.route.queryParams.pipe(map(param => param['raceId'] || 0)).subscribe((raceId) => this.setRace(raceId));
        // this.route.url
    }

    setHeadToHead() {
        if (this.currentRace) {

            this.headToHeads = [];

            this.currentRace.Horses.forEach(e => e.HeadToHead != null ? this.headToHeads.push(e.HeadToHead) : null);
            console.log(this.headToHeads);
        }
    }

    changeSelection(index) {
        this.zone.run(() => {
            this.meetingId = this.meetings[index].Id;
            this.router.navigate(['meeting', this.meetingId]);

            this.getRacesByMeeting();
            this.getMeetingWeather();
            this.getHorseSummaryStats();
        });
    }

    getRacesByMeeting() {
        this.meetingService.getRacesByMeeting(this.meetingId)
            .subscribe(races => this.races = races, null, () => this.transformRacesToMeeting());
    }

    getMeetingWeather() {
        this.meetingService.getWeatherByMeetingId(this.meetingId)
            .subscribe(courseWeather => this.courseWeather = courseWeather);
    }

    getHorseSummaryStats() {
        this.meetingService.getSummaryStatsByMeetingId(this.meetingId)
            .subscribe(summaryStats => this.summaryStats = summaryStats);
    }

    getLiveShows() {

        // Unsub from previous timer
        if (this.liveShowSubscription) {
            this.liveShowSubscription.unsubscribe();
        }

        this.liveShowSubscription = this.meetingService.getLiveShows(this.raceId)
            .subscribe(shows => this.liveShows = this.sortLiveShows(shows));
    }

    sortLiveShows(liveShows: IRaceHorseLiveShow[]) {
        // If there are live shows then create a subscription for the race status
        if (liveShows && liveShows.length > 0) {

            this.meetingService.getRaceStatus(this.raceId)
                .subscribe(status => this.raceStatus = status);
        }
        return liveShows.sort((a, b) => a.LatestOdds.DecimalValue < b.LatestOdds.DecimalValue ? 0 : 1);
    }

    getLiveShowOdds(id: number) {
        const latestOdds = this.liveShows.find(i => i.RaceHorseId === id);

        if (latestOdds) {

            let oddsCaret = '';
            switch (latestOdds.LatestOdds.OddsDirection) {
                case 'Drifting':
                    oddsCaret = '<i class="fas fa-caret-up"></i>&nbsp;';
                    break;
                case 'Shortening':
                    oddsCaret = '<i class="fas fa-caret-down"></i>&nbsp;';
                    break;
            }
            return oddsCaret.concat(latestOdds.LatestOdds.Numerator.toString(), '/', latestOdds.LatestOdds.Denumerator.toString());
        }

    }

    setRace(raceId: number) {

        if (raceId === 0) {
            return;
        }

        this.raceId = raceId;

        this.currentRace = this.meeting.Races.filter(filter => filter.Id == raceId)[0];

        // Set the filters
        this.currentRace.Horses = MeetingHelper.SetHorseRaceRatings(this.currentRace.Horses);

        // Set the head to head
        this.setHeadToHead();

        // Get the live shows
        this.getLiveShows();

        // Set the race preview
        this.racePreview = null;

        if (this.currentRace.RacePreviews && this.currentRace.RacePreviews.length > 0) {
            const longPreview = this.currentRace.RacePreviews.find(i => i.PreviewType.Name === 'Long');
            this.racePreview = longPreview ? longPreview.Preview : null;
        }
        // Filter the prize money
        if (this.currentRace.RacePrizes && this.currentRace.RacePrizes.length > 0) {
            this.currentRace.RacePrizes = this.currentRace.RacePrizes.filter(e => e.Position === 1);
        }

        // Sort the horses
        if (this.currentRace.HasResult) {
            this.currentRace.Horses = this.currentRace
                .Horses.sort((a, b) => (a.FinishPosition || 1000) * 10 > (b.FinishPosition || 1000) * 10 ? 1 : -1);
        } else {
            this.currentRace.Horses = this.currentRace.Horses.sort((a, b) => a.ClothNo * 10 > b.ClothNo * 10 ? 1 : -1);
        }

        // Sort out runners and non runners
        this.nonRunners = this.currentRace.Horses
                        .filter(e => e.Runner === false);

        this.runners = this.currentRace.Horses
                        .filter(e => e.Runner === true);

        this.horseCache = this.runners;

        // Close the open panels
        // this.closeOpenTechnicalPanel();

        // Reset the filters
        this.activeFilters = [];

    }

    getSilk(raceHorseId: number) {
        const currentHorse = this.currentRace.Horses.filter(e => e.Id == raceHorseId)[0];

        if (currentHorse) {
            return this.constants.getSilksBaseUrl() + currentHorse.Silk;
        }

        return '';
    }



    getFinishPosition(finishPosition: number, casualty: ICasualty) {

        if (casualty) {
            return casualty.Abbreviation;
        }

        if (finishPosition) {
            switch (finishPosition) {
                case 1:
                    return finishPosition + 'st';
                case 2:
                    return finishPosition + 'nd';
                case 3:
                    return finishPosition + 'rd';
                default:
                    return finishPosition + 'th';
            }
        }
    }

    toggleFilters() {
        this.filtersActive = this.filtersActive === 'inactive' ? 'active' : 'inactive';
    }

    filterActive(filter) {

        return (this.activeFilters.indexOf(filter) >= 0) ? true : false;
    }

    filterByWin() {

        if (this.filterActive('win')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'win');
        } else {
            this.activeFilters.push('win');
        }

        this.applyFilter();
    }
    filterByCourse() {
        if (this.filterActive('course')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'course');
        } else {
            this.activeFilters.push('course');
        }

        this.applyFilter();
    }
    filterByDistance() {
        if (this.filterActive('distance')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'distance');
        } else {
            this.activeFilters.push('distance');
        }

        this.applyFilter();
    }
    filterByJockey() {
        if (this.filterActive('jockey')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'jockey');
        } else {
            this.activeFilters.push('jockey');
        }

        this.applyFilter();
    }
    filterByPlace() {
        if (this.filterActive('place')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'place');
        } else {
            this.activeFilters.push('place');
        }

        this.applyFilter();
    }
    filterByClass() {
        if (this.filterActive('class')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'class');
        } else {
            this.activeFilters.push('class');
        }

        this.applyFilter();
    }
    filterByGround() {
        if (this.filterActive('ground')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'ground');
        } else {
            this.activeFilters.push('ground');
        }

        this.applyFilter();
    }
    filterByTrainer() {
        if (this.filterActive('trainer')) {
            this.activeFilters = this.activeFilters.filter(e => e !== 'trainer');
        } else {
            this.activeFilters.push('trainer');
        }

        this.applyFilter();
    }

    applyFilter() {
        this.currentRace.Horses = this.horseCache;

        this.runners = this.currentRace.Horses
                        .filter(e => e.RaceHorseStatus == null || (e.RaceHorseStatus != null && e.RaceHorseStatus.Id === 1));
                        
        if (this.filterActive('win')) {
            this.runners =
                this.runners.filter(e => this.summaryStats.filter(i => i.Won === 1).map(i => i.RaceHorseId).indexOf(e.Id) > -1);
        }
        if (this.filterActive('course')) {
            this.runners =
                this.runners
                    .filter(e => this.summaryStats.filter(i => i.WonOnCourse === 1).map(i => i.RaceHorseId).indexOf(e.Id) > -1);
        }
        if (this.filterActive('distance')) {
            this.runners =
                this.runners
                    .filter(e => this.summaryStats.filter(i => i.WonOnDistance === 1).map(i => i.RaceHorseId).indexOf(e.Id) > -1);
        }
        if (this.filterActive('jockey')) {
            this.runners =
                this.runners
                    .filter(e => this.summaryStats.filter(i => i.WonWithJockey === 1).map(i => i.RaceHorseId).indexOf(e.Id) > -1);
        }
        if (this.filterActive('class')) {
            this.runners =
                this.runners
                    .filter(e => this.summaryStats.filter(i => i.WonAtClass === 1).map(i => i.RaceHorseId).indexOf(e.Id) > -1);
        }
        if (this.filterActive('ground')) {
            this.runners =
                this.runners
                    .filter(e => this.summaryStats.filter(i => i.WonOnGround === 1).map(i => i.RaceHorseId).indexOf(e.Id) > -1);
        }
        if (this.filterActive('trainer')) {
            this.runners =
                this.runners
                    .filter(e => this.summaryStats.filter(i => i.WonWithTrainer === 1).map(i => i.RaceHorseId).indexOf(e.Id) > -1);
        }
    }

    getBreadCrumbModel(): BreadCrumbModel[] {
        const result: BreadCrumbModel[] = [];
        result.push({
            name: 'Race Card',
            url: '/meetings'
        });
        result.push({
            name: this.getRaceInfoText(),
            url: null
        });
        return result;
    }
    getRaceInfoText(): string {
        let dateText: string;
        dateText = this.utcPipe.transform(this.currentRace.RaceDate);
        dateText = this.datePipe.transform(dateText, 'HH:mm');
        return this.currentRace.Meeting.Course.Name.concat(', ').concat(dateText);
    }
}
