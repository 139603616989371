﻿import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
    getEqApiUrl(): string {
        return environment.eqApi;
    }

    getIdentityApiUrl(): string {
        return environment.identityApi;
    }

    getIdentityApiName() {
        return environment.identityApiName;
    }

    getIdentityClientName() {
        return environment.identityClientName;
    }

    getIdentityTokenKey() {
        return environment.identityTokenKey;
    }

    getAssetRootUrl() {
        return environment.assetBaseUrl;
    }

    getSilksBaseUrl() {
        return environment.silksBaseUrl;
    }

    getCourseImageBaseUrl() {
        return environment.courseImageBaseUrl;
    }

    getCountryFlagPath() {
        return environment.countryFlagUrlPath;
    }
}
