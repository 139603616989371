import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IRace } from 'app/shared/models/data.models';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit {

  @Input()
  race: IRace;

  @Input()
  public set date(value: Date) {
    if (!value) { return; }
    console.log(value);
    this.totalSeconds = (new Date(value).getTime() - new Date().getTime()) / 1000;
    this.totalSeconds = parseInt(this.totalSeconds.toString());
    if (this.totalSeconds <= 0) { return; }

    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.totalSeconds -= 1;
      this.calculate(this.totalSeconds);
    }, 1000);
  }
  @Output()
  public onComplete: EventEmitter<Date> = new EventEmitter();

  public minutesText: string = "00";
  public hoursText: string = "00";
  private totalSeconds: number = 0;
  private interval: any;
  constructor() { }

  ngOnInit() {

  }
  calculate(totalSeconds: number): void {
    let hours: number = parseInt((totalSeconds / 3600).toString());
    let minutes: number = parseInt(((totalSeconds - hours * 3600) / 60).toString())
    minutes = hours === 0 && minutes === 0 && totalSeconds > 0 ? 1 : minutes;
    this.hoursText = hours < 10 ? "0".concat(hours.toString()) : hours.toString();
    this.minutesText = minutes < 10 ? "0".concat(minutes.toString()) : minutes.toString();
    if (totalSeconds <= 0) {
      this.onComplete.emit();
      clearInterval(this.interval);
    }
  }

}
