import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horse-tutorial',
  templateUrl: './horse-tutorial.component.html'
})
export class HorseTutorialComponent {

  constructor() { }


}
