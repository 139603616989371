import { Component, OnInit, Input } from '@angular/core';
import { IRaceHorseStat } from '../../shared/models/horse-stats.model';
import { MeetingService } from '../../services/meeting-services';

@Component({
  selector: 'app-horse-zone',
  templateUrl: './horse-zone.component.html'
})
export class HorseZoneComponent implements OnInit {

  raceHorseStats: IRaceHorseStat[];

  @Input()
  raceId: number;

  constructor(private meetingService: MeetingService) {

  }

  ngOnInit() {

    this.meetingService.getRaceHorseStatsByRaceId(this.raceId)
      .subscribe(stats => this.raceHorseStats = stats);

  }

}
