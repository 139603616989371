﻿import { Component, OnInit, OnDestroy } from '@angular/core';
import { IRace } from '../shared/models/data.models';
import { MeetingService } from '../services/meeting-services';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'app-eq-race-card',
    templateUrl: 'race-card.component.html',
    providers: [MeetingService]
})

export class RaceCardComponent implements OnInit, OnDestroy {

    private subscription: Subscription;
    raceCard: IRace;
    raceId: number;

    constructor(private meetingService: MeetingService, private route: ActivatedRoute) {
        this.subscription = route.queryParams.subscribe(
            (queryParam: any) => this.raceId = + queryParam['raceId']
        );
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
