import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { IHorseRating } from '../../shared/models/horse-ratings.model';
import { IEquusRating } from '../../shared/models/data.models';
import { MeetingService } from '../../services/meeting-services';

@Component({
  selector: 'app-horse-rating',
  templateUrl: './horse-rating.component.html',
  providers: [MeetingService]
})
export class HorseRatingComponent implements OnInit, OnChanges {

  @Input()
  raceId: number;

  ratings: IHorseRating[];

  constructor(private meetingService: MeetingService) { }

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges() {
    this.loadData();
  }

  loadData() {
    this.meetingService.getHorseRatingsByRaceId(this.raceId)
      .subscribe(ratings => this.ratings = ratings, null, () => this.sortData());
  }

  sortData() {
    this.ratings.forEach(e => e.Ratings = e.Ratings.sort((a, b) => +new Date(b.RaceDateTime) - +new Date(a.RaceId) ));

    console.log(this.ratings);
  }

  getRating(ratings: IEquusRating[], index): string {
    if (ratings.length - 1 >= index) {
      return ratings[index].RatingsTotal.toString();
    }

    return '-';
  }
}
