import { Component, OnInit } from '@angular/core';
import { MeetingService } from '../services/meeting-services';
import { IRace } from 'app/shared/models/data.models';
@Component({
  selector: 'app-innerpage',
  templateUrl: './innerpage.component.html',
  styleUrls: ['./innerpage.component.css'],
  providers: [MeetingService]
})
export class InnerpageComponent implements OnInit {

  public nextRaceDate: Date;
  race: IRace;
  constructor(private meetingService: MeetingService) {

  }

    ngOnInit() {
      this.getNextRaceDate();
    }

    public getNextRaceDate() {
      this.meetingService.getNextRaceDate().subscribe(r => this.race = r);
    }
}
