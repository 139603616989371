import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MeetingService } from '../../services/meeting-services';
import { IRaceHorseStat } from '../../shared/models/horse-stats.model';

@Component({
  selector: 'app-horse-stats',
  templateUrl: './horse-stats.component.html',
  providers: [MeetingService]
})
export class HorseStatsComponent implements OnInit, OnChanges {

  raceHorseStats: IRaceHorseStat[];

  @Input()
  raceId: number;

  constructor(private meetingService: MeetingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges() {
    this.loadData();
  }

  loadData() {
    this.meetingService.getRaceHorseStatsByRaceId(this.raceId)
      .subscribe(stats => this.raceHorseStats = stats);
  }

}
