import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ITopRated } from '../../shared/models/top-rated.model';
import { MeetingService } from '../../services/meeting-services';

@Component({
  selector: 'app-horse-top-rated',
  templateUrl: './horse-top-rated.component.html',
  providers: [MeetingService]
})
export class HorseTopRatedComponent implements OnInit, OnChanges {

  @Input()
  raceId: number;

  topRated: ITopRated[];

  constructor(private meetingService: MeetingService) { }

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges() {
    this.loadData();
  }

  loadData() {
    this.meetingService.getRaceTopRated(this.raceId)
      .subscribe(stats => this.topRated = stats);
  }

  getGraphImage(rank: number) {
    switch (rank) {
      case 1:
        return '/imgs/gauge-primary.png';
      case 2:
        return '/imgs/gauge-warning.png';
      case 3:
        return '/imgs/gauge-danger.png';
    }
  }

  getRatingBlockCss(rank: number) {
    switch (rank) {
      case 1:
        return 'widget-top-rated__rank widget-top-rated__rank--primary';
      case 2:
        return 'widget-top-rated__rank widget-top-rated__rank--warning';
      case 3:
        return 'widget-top-rated__rank widget-top-rated__rank--danger';
    }
  }

  getRatingBlockText(rank: number) {
    switch (rank) {
      case 1:
        return 'Primary';
      case 2:
        return 'Warning';
      case 3:
        return 'Danger';
    }
  }

}
