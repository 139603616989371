﻿import { Component, OnInit } from '@angular/core';
import { IRace, IMeeting } from '../shared/models/data.models';
import { MeetingService } from '../services/meeting-services';
import { MeetingHelper } from '../shared/helpers/meeting';
import { Constants } from 'app/shared/constants';
import { NavigationItem } from '../shared/models/generic.models';
import { DatePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';

@Component({
    moduleId: module.id,
    templateUrl: 'meeting-tile.component.html',
    providers: [MeetingService, DatePipe]
})

export class MeetingTileComponent implements OnInit {

    races: IRace[];
    meetings: IMeeting[];
    results: IRace[];
    navigationItems: NavigationItem[] = [];

    constructor(private meetingService: MeetingService, private constants: Constants, private datePipe: DatePipe) { }

    ngOnInit() {
        this.populateNavigationItems();

        this.meetingService.getRaces()
            .subscribe(races => this.races = races, null, () => this.transformRacesToMeetings());

    }

    transformRacesToMeetings() {
        this.meetings = MeetingHelper.TransformRacesToMeetings(this.races);

        this.meetings = this.meetings.sort((a, b) => a.Course.Name.toLocaleLowerCase() > b.Course.Name.toLocaleLowerCase() ? 1 : -1);

        this.meetings.forEach(
            e => e.Races = e.Races.sort((a, b) => a.RaceDate < b.RaceDate ? 0 : 1)
        );
    }

    populateNavigationItems() {
        this.navigationItems.push(
            {
                Name: 'Today',
                Route: '',
                QueryString: ''
            },
            {
                Name: 'Tomorrow',
                Route: '',
                QueryString: ''
            },
            {
                Name: this.datePipe.transform(this.addDaysToDate(2), 'EEEE'),
                Route: '',
                QueryString: ''
            },
            {
                Name: this.datePipe.transform(this.addDaysToDate(3), 'EEEE'),
                Route: '',
                QueryString: ''
            }
        )

        console.log(this.navigationItems);
    }

    addDaysToDate(days) {
        const date = new Date();
        date.setDate(date.getDate() + days);

        return date;
    }

    getCountryFlagImage(country: string) {
        country = country.replace(' ', '').toLocaleLowerCase();

        return this.constants.getCountryFlagPath().concat('/', country, ('.png'));
    }

}
