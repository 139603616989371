import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../shared/constants';
/*
 * Converts a course image to the full path
*/
@Pipe({name: 'toCourseImagePath'})
export class CourseImagePipe implements PipeTransform {
  constructor(private constants: Constants) {}

  transform(value: string): string {
    return this.constants.getCourseImageBaseUrl() + value;
  }
}
