import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horse-speed',
  templateUrl: './horse-speed.component.html'
})
export class HorseSpeedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
