import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { MeetingTileComponent } from './meetings/meeting-tile.component';
import { MeetingDetailComponent } from './meetings/meeting-detail.component';
import { CourseStatsComponent } from './course/course-stats.component';
import { FastResultsComponent } from './results/fast-results.component';
import { ResultsComponent } from './results/results.component';
import { RaceCardComponent } from './racecard/race-card.component';
import { HorseStatsForRaceComponent } from './horse/horse-stats.component';
import { FlickityComponent, FlickityItem } from './shared/flickity.component';
import * as $ from 'jquery';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LoginComponent } from './login/login.component';
import { IdentityService } from './services/identity.service';
import { AuthGuard } from './guards/auth.guard';
import { InnerpageComponent } from './innerpage/innerpage.component';
import { CountdownComponent } from './countdown/countdown.component';
import { NavigationComponent } from './navigation/navigation.component';
import { Constants } from './shared/constants';
import { Routing } from './app.routing';
import { DateToUtcPipe } from './pipes/datetoutc.pipe';
import { SilkPipe } from './pipes/silk.pipe';
import { HorseHelper } from './shared/helpers/horse';
import { HorseStatsComponent } from './racecards-widgets/horse-stats/horse-stats.component';
import { WidgetsWrapperComponent } from './racecards-widgets/widgets-wrapper/widgets-wrapper.component';
import { HorseTutorialComponent } from './racecards-widgets/horse-tutorial/horse-tutorial.component';
import { HorseZoneComponent } from './racecards-widgets/horse-zone/horse-zone.component';
import { HorseRatingComponent } from './racecards-widgets/horse-rating/horse-rating.component';
import { HorseSpeedComponent } from './racecards-widgets/horse-speed/horse-speed.component';
import { HorseHeatComponent } from './racecards-widgets/horse-heat/horse-heat.component';
import { HorseTopRatedComponent } from './racecards-widgets/horse-top-rated/horse-top-rated.component';
import { HistoryShortNamePipe } from './pipes/history-shortname.pipe';
import { CourseImagePipe } from './pipes/courseimage.pipe';
import { AssetRootPipe } from './pipes/asset-root.pipe';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        BrowserAnimationsModule,
        Routing,
        MatNativeDateModule,
        NoopAnimationsModule,
        MatDatepickerModule
    ],
    providers: [
        IdentityService, AuthGuard, Constants, DateToUtcPipe, HorseHelper, SilkPipe, HistoryShortNamePipe, CourseImagePipe, AssetRootPipe
    ],
    declarations: [
        AppComponent, MeetingTileComponent, RaceCardComponent,
        MeetingDetailComponent, HorseStatsForRaceComponent, FlickityComponent,
        FlickityItem, BreadcrumbComponent, LoginComponent, InnerpageComponent, CountdownComponent, NavigationComponent,
        DateToUtcPipe, FastResultsComponent, ResultsComponent, HorseStatsComponent, WidgetsWrapperComponent, HorseTutorialComponent,
        SilkPipe, HorseZoneComponent, HorseRatingComponent, HorseSpeedComponent, HorseHeatComponent,
        HorseTopRatedComponent, CourseStatsComponent, HistoryShortNamePipe, CourseImagePipe, AssetRootPipe
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
