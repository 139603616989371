// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  eqApi: 'http://dev.api.equusform.com/',
  identityApi: 'http://localhost:5000',
  identityApiName: 'API_1',
  identityClientName: 'equusform',
  identityTokenKey: 'ID4_token',
  assetBaseUrl: 'http://asset.equusform.com/',
  silksBaseUrl: 'http://asset.equusform.com/silks/',
  courseImageBaseUrl: 'http://asset.equusform.com/course_images/',
  countryFlagUrlPath: 'country_flags'
};

