﻿import { Component } from '@angular/core';
import { IRace, IMeeting } from '../shared/models/data.models';
import { MeetingService } from '../services/meeting-services';
import { ActivatedRoute } from '@angular/router';
import { HorseHelper } from '../shared/helpers/horse';
import { MatDatepickerInputEvent } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
    moduleId: module.id,
    templateUrl: 'results.component.html',
    providers: [MeetingService, DatePipe]
})

export class ResultsComponent {
    results: IRace[];
    meetings: IMeeting[];

    constructor(private meetingService: MeetingService, private route: ActivatedRoute, private horseHelper: HorseHelper,
        private datePipe: DatePipe) {
        this.getResults(new Date().toDateString());
    }

    getResults(date: string) {
        this.meetingService.getResults(date)
            .subscribe(races => this.results = races, null, () => this.filterRaces());
    }

    filterRaces() {
        this.results = this.results.filter(i => i.Horses = i.Horses.filter(t => t.Runner === true && t.FinishPosition !== null && (t.Won || t.Placed)));
        this.results.forEach(e => e.Horses.sort((a, b) => a.FinishPosition > b.FinishPosition ? 1 : -1));
        this.results.sort((a, b) => a.RaceDate > b.RaceDate ? 1 : -1);
    }

    dateInput(date: Date) {
        console.log('Inputted date ' + this.datePipe.transform(date, 'shortDate'));
        this.getResults(this.datePipe.transform(date, 'shortDate'));
    }
}
