import { Injectable, Inject } from '@angular/core';
import { Http, RequestOptionsArgs, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Constants } from '../shared/constants';

@Injectable()
export class IdentityService {

    private data: any;
    constructor(private http: Http, private constants: Constants) { }

    public getToken(email: string, password: string): Observable<any> {
        const params: string[] = [
            `username=${email}`,
            `password=${password}`,
            `grant_type=password`,
            `scope=${this.constants.getIdentityApiName()}`,
            `client_id=${this.constants.getIdentityClientName()}`
        ];
        const body = params.join('&');
        return this.http.post(this.constants.getIdentityApiUrl() + '/connect/token', body, this.getDefaultRequestOptions())
    }
    private getDefaultRequestOptions(): RequestOptionsArgs {
        const obj: any = {};
        obj.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return obj;
    }
    private getAuthorizationHeader(token: string): Headers {
        return new Headers({ 'Authorization': 'Bearer ' + token });
    }
}

