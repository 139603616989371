import { Component, ElementRef, NgZone, Host, Directive, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
@Component({
  selector: 'flickity',
  template: '<ng-content></ng-content>'
})

export class FlickityComponent {

  @Output()
  private onSelectionChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() cellAlign = 'center';

  $carousel: JQuery | any;

  initialized = false;

  slides = [];

  constructor(private el: ElementRef, private zone: NgZone) {
  }

  initCarousel() {
    this.zone.runOutsideAngular(() => {
      const wrapper = this.el.nativeElement.children[0];
      const emitter = this.onSelectionChanged;
      const $carousel = this.$carousel = ($(wrapper) as any).flickity({ cellAlign: this.cellAlign, imagesLoaded: true, wrapAround: true, prevNextButtons: false, pageDots: false, percentPosition: true });
      this.$carousel.on('settle.flickity', function () {
        const data = $carousel.data('flickity');
        emitter.emit(data.selectedIndex);
      });
      this.$carousel.on('staticClick.flickity', function (e, pointer, cellElement, cellIndex) {
        if (typeof cellIndex == 'number') {
          $carousel.flickity('select', cellIndex);
        }
      });
    });
    this.initialized = true;
  }

  addSlide(slide) {
    !this.initialized && this.initCarousel();
    this.slides.push(slide)
  }

  selectSlide(index) {
    this.$carousel.flickity('select', index);
  }

  reloadCells() {
    this.$carousel.flickity('resize');
  }
}

@Directive({
  selector: '[flickity-item]',
})
export class FlickityItem implements AfterViewInit {
  @Input('selected') selected = false;
  @Input('selectedIndex') index = 0;

  constructor(private el: ElementRef, @Host() private carousel: FlickityComponent) {
  }



  ngAfterViewInit() {
    this.carousel.addSlide(this);

    if (this.selected) {
      this.carousel.selectSlide(this.index);
    }
  }

}
