import { Pipe, PipeTransform } from '@angular/core';
/*
 * Converts a date to utc
*/
@Pipe({name: 'dateToUtc'})
export class DateToUtcPipe implements PipeTransform {

  transform(value: Date): string {
    return value + 'Z';
  }
}
