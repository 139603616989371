import { Constants } from '../constants';
import { Injectable } from '@angular/core';

@Injectable()
export class HorseHelper {

    constructor(private constants: Constants) {}

    getFullSilkUrl(silkUrl: string) {
        return this.constants.getSilksBaseUrl() + silkUrl;
    }
}
