import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Cookie } from 'ng2-cookies';
import { Constants } from '../shared/constants';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private constants: Constants) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const loggedIn = Cookie.check(this.constants.getIdentityTokenKey());
    if (!loggedIn) {
      this.router.navigate(['/login']);
    }
    return loggedIn;
  }
}
