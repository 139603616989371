import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horse-heat',
  templateUrl: './horse-heat.component.html'
})
export class HorseHeatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
