import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { Constants } from '../shared/constants';
import { IdentityService } from '../services/identity.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [IdentityService]
})
export class LoginComponent implements OnInit {
  private hasError: boolean;
  constructor(private http: IdentityService, private router: Router, private constants: Constants) { }
  ngOnInit() {
    if (Cookie.check(this.constants.getIdentityTokenKey())) { this.router.navigate(['']); }
  }
  login(username: string, password: string) {
    this.hasError = false;
    this.http.getToken(username, password).subscribe(r => {
      const tokenInfo = r.json();
      if (tokenInfo) {
        const expireDate = new Date();
        expireDate.setSeconds(expireDate.getSeconds() + tokenInfo.expires_in)
        Cookie.set(this.constants.getIdentityTokenKey(), tokenInfo.access_token, expireDate);
        this.router.navigate(['']);
      }
    }, (err: Error) => {
      this.handleError(err);
    });
  }
  private handleError(error: Error) {
    console.log('error: ', error);
    this.hasError = true;
  }
}
