﻿import { Component } from '@angular/core';
import { IRace } from '../shared/models/data.models';
import { MeetingService } from '../services/meeting-services';
import { ActivatedRoute } from '@angular/router';
import { HorseHelper } from '../shared/helpers/horse';

@Component({
    moduleId: module.id,
    templateUrl: 'fast-results.component.html',
    providers: [MeetingService]
})

export class FastResultsComponent {
    results: IRace[];

    constructor(private meetingService: MeetingService, private route: ActivatedRoute, private horseHelper: HorseHelper) {
        this.meetingService.getFastResults(new Date())
            .subscribe(races => this.results = this.filterRaces(races));
    }

    filterRaces(races: IRace[]) {
        races = races.filter(i => i.Horses = i.Horses.filter(t => t.Runner === true && t.FinishPosition !== null && (t.Won || t.Placed)));
        races.forEach(e => e.Horses.sort((a, b) => a.FinishPosition > b.FinishPosition ? 1 : -1));
        // races.forEach(e => e.Horses = e.Horses.slice(0, 3));

        return races;
    }

}
