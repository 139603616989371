﻿
import { IMeeting, IRace, IRaceHorse } from '../models/data.models';
import { MathHelper } from './math';

export class MeetingHelper {

    public static TransformRacesToMeetings(races: IRace[]): IMeeting[] {
        const meetings = <IMeeting[]>[];

        for (const race of races) {
            let currentMeeting = meetings.find(item => item.Id == race.Meeting.Id);

            if (currentMeeting == null) {
                meetings.push(race.Meeting);
                currentMeeting = meetings.find(item => item.Id == race.Meeting.Id);
                currentMeeting.Races = [];
            }

            currentMeeting.Races.push(race);
        }

        return meetings;
    }

    public static SetHorseRaceRatings(raceHorses: IRaceHorse[]): IRaceHorse[] {

        const minVal = Math.min.apply(Math, raceHorses.map(function(item) { return item.EquusRating.RatingsTotal}));
        const maxVal = Math.max.apply(Math, raceHorses.map(function(item) { return item.EquusRating.RatingsTotal}));

        for (const raceHorse of raceHorses) {

            const officialRating = raceHorse.RaceHorseRatings.find(e => e.RaceHorseRatingType.Name === 'Official');
            raceHorse.OfficialRating = 0;
            raceHorse.EQRRating = 0;
            raceHorse.RatingRank = 0;

            if (officialRating) {
                raceHorse.OfficialRating = officialRating.RatingValue || 0;
            }

            raceHorse.EQRRating = raceHorse.OfficialRating + (raceHorse.EquusRating.RatingsTotal);
            raceHorse.RatingRank = raceHorse.EquusRating.RatingRank;
            raceHorse.EquuPredictor = MathHelper.ConvertRangeToPercent(raceHorse.EquusRating.RatingsTotal, minVal, maxVal);

        }

        return raceHorses;
    }
}
