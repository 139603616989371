import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition} from '@angular/animations';
import { IRace } from '../../shared/models/data.models';
@Component({
  selector: 'app-widgets-wrapper',
  templateUrl: './widgets-wrapper.component.html',
  animations: [
    trigger('focusPanel', [
        state('inactive', style({
            display: 'none'
        })),
        state('active', style({
            display: 'block',
            height: '65%'
        })),
        transition('inactive => active', animate('150ms ease-in')),
        transition('active => inactive', animate('100ms ease-out'))
    ])
  ]
})
export class WidgetsWrapperComponent {

  @Input()
  currentRace: IRace;

  /*Meeting Detail Technical Cards*/
  panelState = 'inactive';
  openPanel: string;
  mdtcSrc: string;
  mdtcHeading: string;
  currentButton: any;

  panelMapping: any = {
    'eqr': {
      'logo': '/imgs/i-eqr.png',
      'heading': 'EquuRating'
    },
    'eqt': {
      'logo': '/imgs/i-eqt.svg',
      'heading': 'EquuTorial'
    },
    'eh': {
      'logo': '/imgs/i-eh.svg',
      'heading': 'EquuGuide'
    },
    'es': {
      'logo': '/imgs/i-es.svg',
      'heading': 'EquuStable'
    },
    'ez': {
      'logo': '/imgs/i-ez.svg',
      'heading': 'EquuZone'
    },
    'esp': {
      'logo': '/imgs/i-esp.svg',
      'heading': 'EquuSpeed'
    }
  }

  constructor() { }


  closeOpenTechnicalPanel() {
    this.panelState = 'inactive';
    this.openPanel = '';
    this.mdtcHeading = '';
    this.mdtcSrc = '';
    this.removeButtonActiveClass();
    this.currentButton = null;
  }

  removeButtonActiveClass() {
    if (this.currentButton) {
      this.currentButton.className = this.currentButton.className.replace('isActive', '');
    }
  }

  addButtonActiveClass() {
    if (this.currentButton) {
      this.currentButton.className = this.currentButton.className + ' isActive';
    }
  }

  toggleTechnicalPanel(panelName: string, event: any) {

    this.removeButtonActiveClass();
    this.currentButton = event.target;
    this.addButtonActiveClass();

    if (this.openPanel === panelName && this.panelState === 'active') {
        this.closeOpenTechnicalPanel();
        return;
    }

    this.openPanel = panelName;
    this.mdtcHeading = this.panelMapping[panelName].heading;
    this.mdtcSrc = this.panelMapping[panelName].logo;

    this.panelState = 'active';
  }
}
